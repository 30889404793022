<script>
  import '$shoelace/components/carousel-item/carousel-item.js';
  import '$shoelace/components/carousel/carousel.js';
  import '$shoelace/components/details/details.js';

  import { images } from './image_data.js';

  let slides;

  let index = 0;
  const slideChange = event => index = event.detail.index;
</script>

<flex-wrapper>
  <sl-carousel bind:this={slides} pagination navigation mouse-dragging loop on:sl-slide-change={slideChange}>
    {#each images as image (image.src)}
      <sl-carousel-item>
        <img alt={image.alt} src={image.src} />
      </sl-carousel-item>
    {/each}
  </sl-carousel>

  <sl-details summary={images[index].alt}>
    {@html images[index].description}
  </sl-details>
</flex-wrapper>

<style>
  flex-wrapper {
    max-width: 1200px;
    margin: auto;

    display: flex;
    flex-direction: column;
  }

  sl-details {
    padding-top: 16px;
    padding-bottom: 16px;
  }
</style>
