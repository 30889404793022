import App from './App.svelte';

import './app.css';
import '$shoelace/themes/dark.css';

// Shoelace has some static assets (icons); the rollup build copies them into
// the output where they can be served at runtime. This call tells the
// components where to look to find them.
import { setBasePath } from '$shoelace/utilities/base-path.js';
setBasePath('/shoelace');

const app = new App({
  target: document.body,
  props: {}
});

export default app;