export const images = [
    {
      alt: "The exterior front of the house",
      src: "/images/house/one.jpg",
      description: `
        Front view of the house, showing the two car garage and some lawn that
        is decidedly not as green as it could be given the time of year that the
        image was taken.
      `
    },
    {
      alt: "Inside the Foyer",
      src: "/images/house/two.jpg",
      description: `
        Standing in the front area near the kitchen, looking at the front door
        pictured previously.
      `
    },
    {
      alt: "Inside the Foyer, showing high ceiling",
      src: "/images/house/three.jpg",
      description: `
        Area similar to the above, but shooting upwards to show the high vaulted
        ceiling. The living areas in general have 9 foot ceilings.
      `
    },
    {
      alt: "Front Bedroom, new home of moms",
      src: "/images/house/four.jpg",
      description: `
        The first bedroom, next to the front door; this is off of the hallway
        that is visible from the prior picture.<p>

        This bedroom is the one earmarked for moms.
      `
    },
    {
      alt: "Kitchen",
      src: "/images/house/five.jpg",
      description: `
        Shot one of the kitchen, showing the main cooking area.<p>

        The front door closet is visible in the background.
      `
    },
    {
      alt: "Kitchen",
      src: "/images/house/six.jpg",
      description: `
        Another shot of the kitchen, this one showing the location where the
        photographer was standing during the first picture.<p>

        The dishwasher is visible here, as is the built in microwave above the
        stove (which has a small double oven).
      `
    },
    {
      alt: "Dining area",
      src: "/images/house/seven.jpg",
      description: `
        The upstairs dining area, which is adjacent to both the kitchen and the
        upstairs living area.<p>

        There is a door visible on the right; this leads to the sun room, which
        itself has an exterior door to the patio.
      `
    },
    {
      alt: "Kitchen/Dining/Living, plus stairs down",
      src: "/images/house/eight.jpg",
      description: `
        A showcase of the open concept upstairs living area, showing the kitchen,
        living room and dining areas, as well as the stairs down.<p>

        The window to the right of the fireplace opens onto the sun room.
      `
    },
    {
      alt: "Living Room",
      src: "/images/house/nine.jpg",
      description: `
        The upstairs living room area, showing also the windows into the sunroom
        area.
      `
    },
    {
      alt: "Living Room Fireplace",
      src: "/images/house/ten.jpg",
      description: `
        The gas fireplace in the upstairs living room.
      `
    },
    {
      alt: "Sun Room",
      src: "/images/house/eleven.jpg",
      description: `
        The sun room, which also has the exterior back door leading to the patio.<p>

        The tile floor in this area is heated.
      `
    },
    {
      alt: "Laundy/Entrance from Garage",
      src: "/images/house/twelve.jpg",
      description: `
        View from the garage entrance door, which leads through the upstairs
        laundry area and into the main upstairs living area.
      `
    },
    {
      alt: "Master/Primary Bedroom",
      src: "/images/house/thirteen.jpg",
      description: `
        The primary/master bedroom upstairs.<p>

        The bed references in this picture is a king size bed, although that is
        not immediately obvious.
      `
    },
    {
      alt: "Master/Primary Bedroom",
      src: "/images/house/fourteen.jpg",
      description: `
        A second view of the bedroom, which shows the door to the bedrrom on the
        right and the door to the ensuite bathroom and walk in closet on the
        left.
      `
    },
    {
      alt: "Ensuite Bathroom",
      src: "/images/house/fifteen.jpg",
      description: `
        The ensuite bathroom; this includes a jetted tub, a walk in sit-down
        shower with multiple shower heads, a large vanity and of course the
        pooper.<p>

        The walk in closet is also visible through the rear door.
      `
    },
    {
      alt: "Master/Primary Walk in Closet",
      src: "/images/house/sixteen.jpg",
      description: `
        The expansive walk-in closer in the primary/master bedroom, which has
        built in clothes storage.<p>

        The upper of those drawers is roughly 5 feet off the ground, for scale.
      `
    },
    {
      alt: "Upstairs Bathroom",
      src: "/images/house/seventeen.jpg",
      description: `
        The upstairs bathroom, which is adjacent to the upstairs bedroom.
      `
    },
    {
      alt: "Downstairs Bathroom",
      src: "/images/house/eighteen.jpg",
      description: `
        The downstairs bathroom, which is between the game area and the guest
        bedroom/office.
      `
    },
    {
      alt: "Downstairs Guest Bedroom",
      src: "/images/house/nineteen.jpg",
      description: `
        The downstairs guest bedroom.
      `
    },
    {
      alt: "Downstairs Office",
      src: "/images/house/twenty.jpg",
      description: `
        The room that will become the new nerd emporium.
      `
    },
    {
      alt: "Downstairs Game Room",
      src: "/images/house/twenty_one.jpg",
      description: `
        The side of the downstairs that is adjacent to the bedrooms and bathroom
        is what will be the board gaming area.<p>

        Gaming table will be placed roughly where the pool table is pictured here.
      `
    },
    {
      alt: "Downstairs Bar",
      src: "/images/house/twenty_two.jpg",
      description: `
        The opposite side of the basement from the game/bedroom/office area.<p>

        Bar is not actively plumbed, but does come complete with a wine fridge
        and all other bar accoutrements seen here.
      `
    },
    {
      alt: "Downstairs Bar/Game Room",
      src: "/images/house/twenty_three.jpg",
      description: `
        A view from the bar area to the game area.<p>

        The stairs to upstairs are also visible here.
      `
    },
    {
      alt: "Downstairs Ascension Device",
      src: "/images/house/twenty_four.jpg",
      description: `
        A view of the stairs leading up, with the bar and the downstairs
        recreation area.<p>

        The door in the back right leads to the pillow storage area.
      `
    },
    {
      alt: "Downstairs Recreation Area",
      src: "/images/house/twenty_five.jpg",
      description: `
        The "downstairs living room", just off of the bar. <p>

        A view of the pillow storage area is also visible, open on the right.
      `
    },
    {
      alt: "Downstairs Pillow Storage",
      src: "/images/house/twenty_six.jpg",
      description: `
        The downstairs walk-in pillow storage area.
      `
    },
    {
      alt: "Back Patio",
      src: "/images/house/twenty_seven.jpg",
      description: `
        A view of the back patio. This includes a natural gas line to allow for
        a gas BBQ which does not require constant tank filling.
      `
    },
    {
      alt: "Back Patio",
      src: "/images/house/twenty_eight.jpg",
      description: `
        A secondary view of the back patio, showing the full extent of the back
        yard.
      `
    }
  ];